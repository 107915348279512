import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../components/Dashboard';
import React, {ReactElement, useMemo, useState} from 'react';
import TWithProviders, {
  MRT_ColumnDef_Add_Show,
  validateRequired,
} from '../../components/MaterialTable';
import {
  createPermission,
  deletePermission,
  getPermissionList,
} from '../../api/permission';
import {MRT_ColumnDef} from 'material-react-table';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {Alert, AlertColor, Snackbar, SnackbarOrigin} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../constant';
import {CreatedAtColumn} from '../../components/Date/CreatedAtColumn';
dayjs.extend(utc);

function validate(data: any) {
  return {
    scope: !validateRequired(data.scope) ? 'Scope is Required' : '',
    action: !validateRequired(data.action) ? 'Action is Required' : '',
  };
}

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}
function PermissionContent(): ReactElement {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const {t} = useTranslation();

  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;

  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  const columns = useMemo<MRT_ColumnDef_Add_Show<any>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'scope',
        header: t('scopeHeader'),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.scope,
          helperText: validationErrors?.scope,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              scope: undefined,
            }),
        },
      },
      {
        accessorKey: 'action',
        header: t('actionHeader'),
        editSelectOptions: ['Create', 'Edit', 'View', 'Delete', '*'],
        muiEditTextFieldProps: {
          select: true,
          defaultChecked: true,
          defaultValue: true,
          error: !!validationErrors?.action,
          helperText: validationErrors?.action,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              action: undefined,
            }),
        },
      },
      {
        accessorKey: 'createdAt',
        header: t('createdAtHeader'),
        enableEditing: false,
        Cell: ({cell}) => <CreatedAtColumn cell={cell} />,
      },
    ],
    [t, validationErrors],
  );

  return (
    <Container
      id='pricing'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
        }}
      >
        <Typography component='h2' variant='h4' color='text.primary'>
          {t('dashboardMenuPermission')}
        </Typography>
        <Typography variant='body1' color='text.secondary'>
          {t('dashboardTablePermissionDescription')}
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        alignItems='center'
        justifyContent='center'
      ></Grid>
      <TWithProviders
        props={{
          createApi: createPermission,
          //   updateApi: updatePermission,
          getListApi: getPermissionList,
          deleteApi: deletePermission,
        }}
        columns={columns}
        validate={validate}
        setValidateErrors={setValidationErrors}
        handleClick={handleClick}
      ></TWithProviders>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardPermission() {
  const {t} = useTranslation();
  return <Dashboard element={PermissionContent()}></Dashboard>;
}
