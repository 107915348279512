import React, {useState, useEffect} from 'react';
import HomeIcon from '@mui/icons-material/Home';
import {
  Box,
  TextField,
  Button,
  Typography,
  Alert,
  Stack,
  ThemeProvider,
  createTheme,
  Container,
  Tooltip,
  IconButton,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import PleaseLogin from '../../components/User/NoToken';
import '../SignIn.css';
import {
  patchUserMeVerificationEmail,
  postUserMeResendVerificationEmail,
} from '../../api/user';
import {useLocation, useNavigate} from 'react-router-dom';
import {HttpStatusCode} from 'axios';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    background: {
      default: '#f4f6f8',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

const UserVerificationEmailPage = () => {
  const {t, i18n} = useTranslation();
  const localeI18n = localStorage.getItem('i18n');
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const [token, setToken] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    i18n.changeLanguage(localeI18n as string);
    const token = localStorage.getItem('token');
    setToken(token);
  }, []);

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^[a-zA-Z0-9]*$/.test(value) && value.length <= 6) {
      setCode(value);
      setError('');
    } else {
      setError(t('userRegisterVerificationLengthError'));
    }
  };

  const handleSubmit = () => {
    if (code.length !== 6) {
      setError(t('userRegisterVerificationHelpTxt'));
    } else {
      patchUserMeVerificationEmail({code})
        ?.then(d => {
          if (d.status !== HttpStatusCode.Ok) {
            setError(JSON.stringify(d.data));
            return;
          }
          setSuccess(true);

          const searchParams = new URLSearchParams(location.search);
          const returnUrl = searchParams.get('returnUrl');
          const targetPath = returnUrl ? returnUrl : '/dashboard';

          setTimeout(() => {
            window.location.href = targetPath;
          }, 5000);
        })
        .catch(e => {
          setError(e);
        });
    }
  };

  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [resendTimer]);

  const handleResendCode = () => {
    setResendTimer(60);
    setSuccess(false);
    postUserMeResendVerificationEmail();
  };

  if (!token) {
    return <PleaseLogin t={t}></PleaseLogin>;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className='background-animation'>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
      </div>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        mb={2}
      >
        <Tooltip title={'backToHome'}>
          <IconButton onClick={() => navigate('/')}>
            <HomeIcon fontSize='large' color='primary' />
          </IconButton>
        </Tooltip>
      </Box>
      <Container component='main' maxWidth='xs'>
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: 2,
            boxShadow: 3,
            p: 4,
            mt: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Stack spacing={2} sx={{width: '100%', maxWidth: 400}}>
            <Typography variant='h5' align='center' gutterBottom>
              {t('userRegisterVerification')}
            </Typography>
            <Typography variant='body1' align='center' gutterBottom>
              {t('userRegisterVerificationContent')}
            </Typography>

            {success && (
              <Alert severity='success'>
                {t('userRegisterVerificationOk')}
              </Alert>
            )}
            {error && <Alert severity='error'>{error}</Alert>}

            <TextField
              label='驗證碼'
              variant='outlined'
              value={code}
              onChange={handleCodeChange}
              fullWidth
              inputProps={{maxLength: 6}}
              helperText={t('userRegisterVerificationHelpTxt')}
            />

            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={handleSubmit}
              disabled={success}
            >
              {t('userRegisterVerificationSubmit')}
            </Button>

            <Button
              variant='text'
              color='secondary'
              fullWidth
              onClick={handleResendCode}
              disabled={resendTimer > 0}
            >
              {t('userRegisterVerificationResend')} (
              {resendTimer > 0
                ? resendTimer
                : t('userRegisterVerificationResendOk')}
              )
            </Button>
          </Stack>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default UserVerificationEmailPage;
