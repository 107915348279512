import {Helmet} from 'react-helmet';

function SEO({
  title,
  description,
  image,
  url,
}: {
  title: string;
  description: string;
  image: string;
  url?: string;
}) {
  return (
    <Helmet>
      <meta charSet='utf-8' />
      <title>{title}</title>
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <meta name='theme-color' content='#000000' />
      <meta name='description' content={description} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={image} />
      <meta
        property='og:url'
        content={url || 'https://zusers.zzztech.com.tw'}
      />
      <link rel='icon' href='/favicon.ico' />
    </Helmet>
  );
}

export default SEO;
