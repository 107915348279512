import {Switch, FormControlLabel} from '@mui/material';
import {TFunction} from 'i18next';
import {MRT_Cell, MRT_Column, MRT_Row} from 'material-react-table';
import React, {useEffect} from 'react';

export default function EditTableBooleanColumn({
  t,
  cell,
  row,
  column,
  label,
  defaultValue = false,
}: {
  t: TFunction<'translation', undefined>;
  cell: MRT_Cell<any, unknown>;
  row: MRT_Row<any>;
  column: MRT_Column<any, unknown>;
  label?: string;
  defaultValue?: boolean;
}) {
  const value = cell.getValue();
  const [editValue, setEditValue] = React.useState<boolean>(
    (value as boolean) || defaultValue,
  );

  useEffect(() => {
    row._valuesCache[column.id] = editValue;
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked; // 這是布爾值
    setEditValue(newValue);

    row._valuesCache[column.id] = newValue;
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={editValue}
          onChange={handleChange}
          color='primary'
          inputProps={{'aria-label': 'controlled'}}
        />
      }
      label={label ?? t('isShow')}
    />
  );
}
