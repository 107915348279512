import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

export const StatusColumn = ({cell, t}: any) => {
  const status = cell.getValue();

  const getStatusDisplay = (status: any) => {
    switch (status) {
      case 'Pending':
        return (
          <span
            style={{display: 'flex', alignItems: 'center', color: '#FFA500'}}
          >
            <HourglassEmptyIcon style={{marginRight: 5}} />
            {t('statusPending')}
          </span>
        );
      case 'Finish':
        return (
          <span
            style={{display: 'flex', alignItems: 'center', color: '#4CAF50'}}
          >
            <CheckCircleIcon style={{marginRight: 5}} />
            {t('statusFinish')}
          </span>
        );
      case 'Fail':
        return (
          <span
            style={{display: 'flex', alignItems: 'center', color: '#F44336'}}
          >
            <CancelIcon style={{marginRight: 5}} />
            {t('statusFail')}
          </span>
        );
      default:
        return status;
    }
  };

  return getStatusDisplay(status);
};
