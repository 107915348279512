import React from 'react';
import './App.css';
import LandingPage from './page/LandingPage';
import SEO from './components/SEO';

function App() {
  return (
    <div className='App'>
      <SEO
        title='ZUsers Platform'
        description='迅速建立平台權限的開發環境工具，打造完美產品！'
        image='%PUBLIC_URL%/logo192.png'
      />
      <LandingPage />
    </div>
  );
}

export default App;
