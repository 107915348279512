import * as React from 'react';
import {styled, createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import {
  mainListItems,
  mobileMenuItems,
  secondaryListItems,
  thirdListItems,
} from './listItems';
import {getUserMe} from '../api/user';
import {HttpStatusCode} from 'axios';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import LanguageSelector from './LanguageSelector';
import {ZUSER_PLATFORM_ID} from '../constant';
import {getPlatformPublic} from '../api/platform';
import {PlatformDto} from '../api/dto/platform';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  SwipeableDrawer,
  useMediaQuery,
} from '@mui/material';
import NotificationButton from './User/Notification';

const drawerWidth: number = 240;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  [theme.breakpoints.down('sm')]: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  ...(open && {
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: prop => prop !== 'open'})(
  ({theme, open}) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const defaultTheme = createTheme();

export default function Dashboard({element}: {element: React.ReactElement}) {
  const {t, i18n} = useTranslation();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const localeI18n = localStorage.getItem('i18n');
  const [open, setOpen] = React.useState(
    localStorage.getItem('drawerOpen') === 'true',
  );

  const [platform, setPlatform] = React.useState<PlatformDto>();

  const navigate = useNavigate();
  const toggleDrawer = () => {
    const newOpenState = !open;
    setOpen(newOpenState);
    localStorage.setItem('drawerOpen', String(newOpenState));
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  React.useEffect(() => {
    getUserMe()?.then(d => {
      if (d.status !== HttpStatusCode.Ok) {
        navigate('/sign-in');
      }

      if (
        d.data?.data?.platformId === ZUSER_PLATFORM_ID &&
        d.data?.data?.ownerPlatformCount === 0
      ) {
        navigate('/user/step');
      }
      localStorage.setItem('groupId', d?.data?.data?.groupId);
    });
  }, [navigate]);

  React.useEffect(() => {
    getPlatformPublic()?.then(d => {
      setPlatform(d.data?.data);
    });
  }, [navigate]);

  React.useEffect(() => {
    if (localeI18n) i18n.changeLanguage(localeI18n);
  }, [localeI18n, i18n]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{display: 'flex'}}>
        <CssBaseline />
        <AppBar position='fixed' open={open}>
          <Toolbar sx={{pr: '24px'}}>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='open drawer'
              onClick={toggleDrawer}
              sx={theme => ({
                marginRight: '36px',
                [theme.breakpoints.down('sm')]: {
                  display: 'block',
                },
                [theme.breakpoints.up('sm')]: {
                  ...(open && {display: 'none'}),
                },
              })}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component='h1'
              variant='h6'
              color='inherit'
              noWrap
              sx={{flexGrow: 1}}
            >
              {t('dashboardTitle')}
            </Typography>
            <LanguageSelector i18n={i18n} />
            <NotificationButton t={t} />
            <IconButton color='inherit' onClick={handleLogout}>
              <LogoutTwoToneIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <>
          {/* 桌面版選單 */}
          {!isMobile && (
            <Drawer variant='permanent' anchor='left' open={open}>
              <Toolbar
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  px: [1],
                }}
              >
                <Typography marginRight='auto'>
                  <Link href={platform?.homePageUrl ?? '/'}>
                    <img
                      src={platform?.icon || '/logo-symbol.png'}
                      alt='brand-logo'
                      width={60}
                      height={50}
                    />
                  </Link>
                </Typography>
                <IconButton onClick={toggleDrawer}>
                  <ChevronLeftIcon />
                </IconButton>
              </Toolbar>
              <Divider />
              <List component='nav'>
                {mainListItems(t)}
                <Divider sx={{my: 1}} />
                {secondaryListItems(t)}
                <Divider sx={{my: 1}} />
                {thirdListItems(t)}
              </List>
            </Drawer>
          )}

          {/* 手機版選單 */}
          {isMobile && (
            <SwipeableDrawer
              anchor='bottom'
              open={open}
              onClose={toggleDrawer}
              onOpen={toggleDrawer}
            >
              <List
                sx={{
                  display: 'flex',
                  overflowX: 'auto', // 啟用水平滾動
                  whiteSpace: 'nowrap', // 確保不換行，防止元素折行導致消失
                  scrollBehavior: 'smooth',
                  WebkitOverflowScrolling: 'touch',
                }}
              >
                {mobileMenuItems(t).map((item, index) =>
                  item.section ? (
                    <React.Fragment key={index}>
                      <ListSubheader component='div' inset>
                        {t(item.section)}
                      </ListSubheader>
                      {item.items.map((subItem, subIndex) => (
                        <ListItem
                          key={index}
                          sx={{
                            flexDirection: 'column',
                            textAlign: 'center',
                          }}
                          onClick={subItem.onClick}
                        >
                          <ListItemIcon
                            sx={{
                              width: 70,
                              height: 70,
                              borderRadius: '50%',
                              backgroundColor: '#f0f0f0',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              mb: 1,
                            }}
                          >
                            {subItem.icon}
                          </ListItemIcon>
                          <ListItemText primary={t(subItem?.text as string)} />
                        </ListItem>
                      ))}
                    </React.Fragment>
                  ) : (
                    <ListItem
                      key={index}
                      sx={{
                        flexDirection: 'column',
                        textAlign: 'center',
                      }}
                      onClick={item.onClick}
                    >
                      <ListItemIcon
                        sx={{
                          width: 70,
                          height: 70,
                          borderRadius: '50%',
                          backgroundColor: '#f0f0f0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mb: 1,
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={t(item?.text as string)} />
                    </ListItem>
                  ),
                )}
              </List>
            </SwipeableDrawer>
          )}
        </>

        <Box
          component='main'
          sx={theme => ({
            flexGrow: 1,
            [theme.breakpoints.down('sm')]: {
              marginTop: 5,
            },
          })}
        >
          {element}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
