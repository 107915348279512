import {Box, Typography, Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {TFunction} from 'i18next';

const PleaseLogin = ({t}: {t: TFunction<'translation', undefined>}) => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/sign-in');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        textAlign: 'center',
        bgcolor: '#f4f4f9',
        padding: 3,
      }}
    >
      <Typography variant='h5' gutterBottom>
        {t('userNeedLogin')}
      </Typography>
      <Typography variant='body1' sx={{marginBottom: 2}}>
        {t('userNeedLoginContent')}
      </Typography>
      <Button variant='contained' color='primary' onClick={handleLoginRedirect}>
        {t('userNeedLoginGoToLoginPage')}
      </Button>
    </Box>
  );
};

export default PleaseLogin;
