import React, {useState, useEffect} from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Snackbar,
  Alert,
  Paper,
  CircularProgress,
} from '@mui/material';
import {CreditCard} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {UserDto} from '../../api/dto/user';
import {postGroupPlanPay} from '../../api/groupPlan';
import {TFunction} from 'i18next';
import {HttpStatusCode} from 'axios';

type CardType =
  | 'VISA'
  | 'visa'
  | 'MASTERCARD'
  | 'mastercard'
  | 'JCB'
  | 'jcb'
  | null;

type TappayPrimeToPayFormProps = {
  user?: UserDto;
  i18n: string;
  planId: string;
  t: TFunction<'translation', undefined>;
};

declare global {
  interface Window {
    TPDirect: any;
  }
}

const cardIcon = (type: CardType) => {
  switch (type) {
    case 'VISA':
    case 'visa':
      return <CreditCard />;
    case 'MASTERCARD':
    case 'mastercard':
      return <CreditCard />;
    case 'JCB':
    case 'jcb':
      return <CreditCard />;
    default:
      return <CreditCard />;
  }
};

const TappayPrimeToPayForm: React.FC<TappayPrimeToPayFormProps> = ({
  user,
  i18n,
  planId,
  t,
}) => {
  const [_, setPrime] = useState<string | null>(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [canGetPrime, setCanGetPrime] = useState(false);
  const [cardType, setCardType] = useState<CardType>(null);
  const [alert, setAlert] = useState({
    open: false,
    severity: 'success' as 'success' | 'error',
    message: '',
  });
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const TAPPAY_APP_ID = process.env.REACT_APP_TAPPAY_CLIENT_ID;
  const TAPPAY_APP_KEY = process.env.REACT_APP_TAPPAY_CLIENT_SECRET;
  const TAPPAY_SERVER_TYPE =
    process.env.NODE_ENV === 'production' ? 'production' : 'sandbox';

  const tappayInit = () => {
    if (typeof window.TPDirect !== 'undefined') {
      const TPDirect = window.TPDirect;
      TPDirect.setupSDK(TAPPAY_APP_ID, TAPPAY_APP_KEY, TAPPAY_SERVER_TYPE);

      TPDirect.card.setup({
        fields: {
          number: {element: '#card-number', placeholder: '**** **** **** ****'},
          expirationDate: {
            element: '#card-expiration-date',
            placeholder: 'MM / YY',
          },
          ccv: {element: '#card-ccv', placeholder: 'CVV'},
        },
        styles: {
          input: {color: '#495057', fontSize: '16px'},
          '.valid': {color: 'green'},
          '.invalid': {color: 'red'},
        },
        isMaskCreditCardNumber: true,
        maskCreditCardNumberRange: {beginIndex: 6, endIndex: 11},
      });

      TPDirect.card.onUpdate(
        (update: {canGetPrime: boolean; cardType: CardType}) => {
          setCanGetPrime(update.canGetPrime);
          setCardType(update.cardType);
        },
      );
    }
  };

  useEffect(() => {
    if (typeof window.TPDirect !== 'undefined') {
      tappayInit();
    } else {
      const script = document.createElement('script');
      script.src = 'https://js.tappaysdk.com/tpdirect/v5.13.0';
      script.async = true;
      script.onload = tappayInit;
      document.body.appendChild(script);
    }
  }, []);

  const handlePayment = () => {
    if (typeof window.TPDirect !== 'undefined') {
      setLoading(true);
      const TPDirect = window.TPDirect;
      TPDirect.card.getPrime((res: {status: number; card: {prime: string}}) => {
        if (res.status === 0) {
          setPrime(res.card.prime);
          const prime = res.card.prime;
          const data = {
            prime,
            planId,
            language: i18n,
          };
          postGroupPlanPay(data)
            .then((d: any) => {
              if (d.status === HttpStatusCode.Ok) {
                setIsPaymentSuccessful(true);
                setAlert({
                  open: true,
                  severity: 'success',
                  message: 'Payment successful!',
                });
                setTimeout(() => navigate('/'), 3000); // 3秒後跳轉到首頁
              } else {
                setAlert({
                  open: true,
                  severity: 'error',
                  message: JSON.stringify(d?.data),
                });
              }
            })
            .catch((err: any) => {
              setAlert({
                open: true,
                severity: 'error',
                message: err?.response?.data?.message,
              });
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          setAlert({
            open: true,
            severity: 'error',
            message: t('planPayFailGetPrime'),
          });
          setLoading(false);
        }
      });
    }
  };

  return (
    <Box>
      {isPaymentSuccessful ? (
        <Typography variant='h6' sx={{textAlign: 'center', mt: 3}}>
          {t('planPaySuccess')}
        </Typography>
      ) : (
        <>
          <Typography variant='h6'>{t('planPayCreditCardPayment')}</Typography>
          <Paper elevation={3} sx={{p: 3, mt: 2}}>
            {/* 卡片字段 */}
            <Box sx={{mb: 2}}>
              <Typography variant='body1'>{t('planPayCardNumber')}</Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <Box sx={{position: 'absolute', right: 10, color: '#888'}}>
                  {cardIcon(cardType)}
                </Box>
                <div
                  id='card-number'
                  style={{width: '100%', padding: '0.5rem'}}
                ></div>
              </Box>
            </Box>
            <Box sx={{mb: 2}}>
              <Typography variant='body1'>{t('planPayExpiredAt')}</Typography>
              <div
                id='card-expiration-date'
                style={{width: '100%', padding: '0.5rem'}}
              ></div>
            </Box>
            <Box sx={{mb: 2}}>
              <Typography variant='body1'>{t('planPayCardCVC')}</Typography>
              <div
                id='card-ccv'
                style={{width: '100%', padding: '0.5rem'}}
              ></div>
            </Box>
            {/* 用户信息字段 */}
            <TextField
              fullWidth
              label={t('planPayName')}
              value={user?.username || name}
              disabled={!!user}
              onChange={e => setName(e.target.value)}
              sx={{mb: 2}}
            />
            <TextField
              fullWidth
              label={t('planPayEmail')}
              disabled={!!user}
              value={user?.email || email}
              onChange={e => setEmail(e.target.value)}
              sx={{mb: 2}}
            />
            <Button
              variant='contained'
              color='primary'
              disabled={
                loading ||
                !canGetPrime ||
                !(user?.username || name) ||
                !(user?.email || email)
              }
              fullWidth
              onClick={handlePayment}
              startIcon={loading ? <CircularProgress size={20} /> : null}
            >
              {loading ? t('loading') : t('planPaySubmit')}
            </Button>
          </Paper>
        </>
      )}

      <Snackbar
        open={alert.open}
        autoHideDuration={5000}
        onClose={() => setAlert({...alert, open: false})}
      >
        <Alert
          onClose={() => setAlert({...alert, open: false})}
          severity={alert.severity}
          sx={{width: '100%'}}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TappayPrimeToPayForm;
