import callApi from './index';
import {PostGroupPlanPayDto} from './dto/groupPlan';
import {ZUSER_PLATFORM_ID} from '../constant';

export const postGroupPlanPay = (data: PostGroupPlanPayDto) => {
  return callApi('post', `/group/plan/pay`, data, {
    headers: {
      platformId: ZUSER_PLATFORM_ID,
    },
  }) as any;
};
