import React, {memo} from 'react';
import {Handle, Position} from '@xyflow/react';
import {Box, Card, Avatar, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom'; // 引入 useNavigate

const CustomNode: React.FC<any> = ({data, edges}) => {
  const navigate = useNavigate(); // 路由导航函数

  const handleNodeClick = () => {
    if (data.link) {
      navigate(data.link); // 跳转到目标链接
    }
  };

  return (
    <Card
      onClick={handleNodeClick} // 添加点击事件
      sx={{
        width: 160,
        padding: 1,
        boxShadow: 3,
        borderRadius: 2,
        backgroundColor: 'white',
        border: '1px solid #ddd',
        cursor: 'pointer', // 鼠标变成手形
        '&:hover': {
          boxShadow: 6,
          border: '1px solid #ccc',
        },
      }}
    >
      <Box display='flex' alignItems='center'>
        <Avatar
          sx={{
            bgcolor: 'primary.main',
            width: 30,
            height: 30,
            fontSize: 16,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {data.icon}
        </Avatar>
        <Box ml={1}>
          <Typography
            variant='h6'
            sx={{fontWeight: 'bold', color: 'text.primary'}}
          >
            {data.label}
          </Typography>
        </Box>
      </Box>

      <Box display='flex' justifyContent='space-between' mt={2}>
        <Handle
          type='target'
          position={Position.Top}
          className='w-16 !bg-teal-500'
        />
        <Handle
          type='source'
          position={Position.Bottom}
          className='w-16 !bg-teal-500'
        />
      </Box>
    </Card>
  );
};

export default memo(CustomNode);
