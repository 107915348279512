import React, {useState, useEffect, useRef} from 'react';
import HomeIcon from '@mui/icons-material/Home';
import * as THREE from 'three';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Typography,
  Card,
  CardContent,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Snackbar,
  Alert,
  AlertColor,
  Tooltip,
  IconButton,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {createPlatform, getPlatformByName} from '../../api/platform';
import {useNavigate} from 'react-router-dom';
import {PricingCard} from '../../components/Pricing';
import {getPlanPublicList} from '../../api/plan';
import {PlanDto} from '../../api/dto/plan';
import {HttpStatusCode} from 'axios';
import {PlatformDto} from '../../api/dto/platform';
import {ALERT_HIDE_DURATION} from '../../constant';
import {getUserMe} from '../../api/user';

type UserAccountType = 'Personal' | 'Company';

enum PlanEnum {
  Free = 'Free',
  Basic = 'Basic',
  Pro = 'Pro',
  Premium = 'Premium',
  ExPremium = 'ExPremium',
}

const UserSteps: React.FC = () => {
  const {t, i18n} = useTranslation();
  const localeI18n = localStorage.getItem('i18n');
  const [planObj, setPlanObj] = useState<{[k: string]: PlanDto}>();
  const [plan, setPlan] = useState<PlanDto>();

  const steps = [
    t('userStepCreatePlatform'),
    t('userStepSelectAccountType'),
    t('userStepSelectEstimatedUsers'),
    t('userStepRecommendPaymentPlan'),
    t('userStepPlatformInfo'),
  ];
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [platformName, setPlatformName] = useState<string>('');
  const [newPlatform, setNewPlatform] = useState<PlatformDto>();
  const [userEstimate, setUserEstimate] = useState<PlanEnum>();

  const [userAccountType, setUserAccountType] = useState<UserAccountType | ''>(
    '',
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [isRadioError, setIsRadioError] = useState(false);
  const [recommendPlan, setRecommendPlan] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackbarType, setShowSnackbarType] =
    useState<AlertColor>('success');
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>();
  const [isFinish, setIsFinish] = useState(false);
  const canvasRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getUserMe()?.then(d => {
      if (!d?.data?.data?.isEmailVerify) {
        window.location.replace(`/user/email/verification`);
      }
    });
  }, []);

  useEffect(() => {
    i18n.changeLanguage(localeI18n as string);
    getPlanPublicList()
      ?.then(d => {
        const obj = d.data.items.reduce((n, curr) => {
          return {...n, [curr.name]: curr};
        }, {});
        setPlanObj(obj);
      })
      .catch(e => {
        setShowSnackbarType('error');
        setShowSnackbarMessage(e);
        setShowSnackbar(true);
      });
  }, []);

  useEffect(() => {
    const scene = new THREE.Scene();

    scene.background = new THREE.Color(0xffffff);
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000,
    );
    camera.position.z = 5;

    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    canvasRef.current?.appendChild(renderer.domElement);

    // Create floating stars with gradient colors
    const starGeometry = new THREE.SphereGeometry(0.05, 24, 24);

    const starMaterials = [
      new THREE.MeshBasicMaterial({color: 0x7f7fff}), // Light blue
      new THREE.MeshBasicMaterial({color: 0x9370db}), // Medium purple
      new THREE.MeshBasicMaterial({color: 0xccffff}), // Cyan
    ];

    const stars = Array.from({length: 100}, () => {
      const starMaterial =
        starMaterials[Math.floor(Math.random() * starMaterials.length)];
      const star = new THREE.Mesh(starGeometry, starMaterial);

      star.position.set(
        (Math.random() - 0.5) * 10, // Random X position
        (Math.random() - 0.5) * 10, // Random Y position
        (Math.random() - 0.5) * 10, // Random Z position
      );
      scene.add(star);
      return {
        star,
        velocity: new THREE.Vector3(
          (Math.random() - 0.5) * 0.02, // Random X velocity
          (Math.random() - 0.5) * 0.02, // Random Y velocity
          (Math.random() - 0.5) * 0.02, // Random Z velocity
        ),
      };
    });

    const animate = () => {
      requestAnimationFrame(animate);

      stars.forEach(({star, velocity}) => {
        star.position.add(velocity);

        if (star.position.x > 5 || star.position.x < -5) velocity.x *= -1;
        if (star.position.y > 5 || star.position.y < -5) velocity.y *= -1;
        if (star.position.z > 5 || star.position.z < -5) velocity.z *= -1;
      });

      renderer.render(scene, camera);
    };

    animate();

    return () => {
      renderer.dispose();
    };
  }, []);

  const handleNext = async () => {
    switch (activeStep) {
      case 0:
        const platform = await getPlatformByName(platformName);
        if (platform?.data.data.id) {
          setErrorMessage(t('userStepPlatformNameAlreadyHave'));
          return;
        }

        break;
      case 1:
        if (userAccountType === '') {
          setIsRadioError(true);
          return;
        }
        break;
      case 2:
        if (!userEstimate) {
          setIsRadioError(true);
          return;
        }

        switch (userEstimate) {
          case PlanEnum.Free:
            setPlan(planObj?.[PlanEnum.Free]);
            break;
          case PlanEnum.Basic:
            setPlan(planObj?.[PlanEnum.Basic]);
            break;
          case PlanEnum.Pro:
            setPlan(planObj?.[PlanEnum.Pro]);
            break;
          case PlanEnum.Premium:
          case PlanEnum.ExPremium:
            setPlan(planObj?.[PlanEnum.Premium]);
            break;
          default:
            setPlan(planObj?.[PlanEnum.Free]);
        }

        setRecommendPlan(true);
        break;
      case 3:
        const newPlatform = await createPlatform({
          name: platformName,
        });
        if (newPlatform?.status !== HttpStatusCode.Ok) {
          if (newPlatform?.data.code === 4043) {
            setShowSnackbarType('error');
            setShowSnackbarMessage(t('userStepCreatePlatformQuotaError'));
            setShowSnackbar(true);
          } else {
            setShowSnackbarType('error');
            setShowSnackbarMessage(t('userStepCreatePlatformError'));
            setShowSnackbar(true);
          }
        } else {
          setNewPlatform(newPlatform.data.data);
          setIsFinish(true);
          handleFinish(newPlatform.data.data);
        }

        break;
    }

    setActiveStep(prevStep => prevStep + 1);
  };

  const handleBack = () => {
    setIsRadioError(false);
    setActiveStep(prevStep => prevStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setPlatformName('');
    setUserEstimate(undefined);
    setRecommendPlan(false);
  };

  const handleFinish = (newPlatform: PlatformDto) => {
    setShowSnackbarType('success');
    setShowSnackbarMessage(t('userStepFinishMessage'));
    setShowSnackbar(true);
    setTimeout(() => {
      localStorage.setItem('token', '');
      navigate(`/sign-in?platformId=${newPlatform?.id}`);
    }, 5000);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  if (true) {
    return (
      <Box
        ref={canvasRef}
        sx={{
          position: 'relative',
          width: '100vw',
          height: '100vh',
          overflowY: 'auto', // 僅垂直方向滾動
          overflowX: 'hidden', // 水平方向禁止滾動
        }}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          mb={2}
        >
          <Tooltip title={'backToHome'}>
            <IconButton onClick={() => navigate('/')}>
              <HomeIcon fontSize='large' color='primary' />
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            padding: 3,
            borderRadius: 2,
          }}
        >
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{mt: 2}}>
            {activeStep === 0 && (
              <Card variant='outlined'>
                <CardContent>
                  <Typography variant='h6'>
                    Step 1: {t('userStepCreatePlatform')}
                  </Typography>
                  <TextField
                    label={t('userStepPlatformName')}
                    fullWidth
                    value={platformName}
                    onChange={e => {
                      setPlatformName(e.target.value);
                      setErrorMessage('');
                    }}
                    sx={{mt: 2}}
                    error={!!errorMessage}
                    helperText={errorMessage}
                  />
                  {platformName && (
                    <Typography variant='body1' sx={{mt: 2}}>
                      {t('userStepPlatformName')}: {platformName}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            )}

            {activeStep === 1 && (
              <Card variant='outlined'>
                <CardContent>
                  <Typography variant='h6'>
                    Step 2: {t('userStepSelectAccountType')}
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      value={userAccountType}
                      onChange={e => {
                        setUserAccountType(e.target.value as UserAccountType);
                        setIsRadioError(false);
                      }}
                    >
                      <FormControlLabel
                        value='Personal'
                        control={<Radio />}
                        label={t('userStepSelectAccountTypePersonal')}
                      />
                      <FormControlLabel
                        value='Company'
                        control={<Radio />}
                        label={t('userStepSelectAccountTypeCompany')}
                      />
                    </RadioGroup>
                    {isRadioError && (
                      <FormHelperText>
                        {t('userStepEstimateRequired')}
                      </FormHelperText>
                    )}
                  </FormControl>
                </CardContent>
              </Card>
            )}

            {activeStep === 2 && (
              <Card variant='outlined'>
                <CardContent>
                  <Typography variant='h6'>
                    Step 3: {t('userStepSelectEstimatedUsers')}
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      value={userEstimate}
                      onChange={e => {
                        setUserEstimate(e.target.value as unknown as PlanEnum);
                        setIsRadioError(false);
                      }}
                    >
                      <FormControlLabel
                        value={PlanEnum.Free}
                        control={<Radio />}
                        label='< 1000'
                      />
                      <FormControlLabel
                        value={PlanEnum.Basic}
                        control={<Radio />}
                        label='1000 ~ 99999'
                      />
                      <FormControlLabel
                        value={PlanEnum.Pro}
                        control={<Radio />}
                        label='10000 ~ 1M'
                      />
                      <FormControlLabel
                        value={PlanEnum.Premium}
                        control={<Radio />}
                        label='1M ~ 100M'
                      />
                      <FormControlLabel
                        value={PlanEnum.ExPremium}
                        control={<Radio />}
                        label='> 100M'
                      />
                    </RadioGroup>
                    {isRadioError && (
                      <FormHelperText>
                        {t('userStepEstimateRequired')}
                      </FormHelperText>
                    )}
                  </FormControl>
                </CardContent>
              </Card>
            )}

            {activeStep === 3 && recommendPlan && (
              <Card variant='outlined'>
                <CardContent>
                  <Typography variant='h6'>
                    Step 4: {t('userStepRecommendPaymentPlan')}
                  </Typography>
                  <Typography variant='body1'>
                    {t('userStepRecommendPlan')}
                  </Typography>
                  <PricingCard t={t} plan={plan as PlanDto} />
                  <Button variant='outlined' sx={{mt: 2}} onClick={handleNext}>
                    {t('userStepSkip')}
                  </Button>
                </CardContent>
              </Card>
            )}

            {activeStep === 4 && (
              <Card variant='outlined'>
                <CardContent>
                  <Typography variant='h6'>
                    Step 4: {t('userStepPlatformInfo')}
                  </Typography>
                  <Typography variant='body1'>
                    {t('userStepPlatformName')}:{' '}
                    <strong>{newPlatform?.name}</strong>
                  </Typography>
                  <Typography variant='body1'>
                    {t('userStepPlatformHashMsg')}{' '}
                    <strong>{newPlatform?.hash}</strong>
                  </Typography>
                  {isFinish ? (
                    <>
                      <Typography variant='body1' style={{fontWeight: 'bold'}}>
                        {t('userStepFinishHelper')}{' '}
                      </Typography>
                      <Typography variant='body1'>
                        {t('userStepReadyStart')}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        variant='body1'
                        style={{fontWeight: 'bold', color: 'red'}}
                      >
                        {showSnackbarMessage}{' '}
                      </Typography>
                    </>
                  )}
                </CardContent>
              </Card>
            )}

            <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
              <Button
                color='inherit'
                disabled={activeStep === 0 || activeStep === steps.length - 1}
                onClick={handleBack}
                sx={{mr: 1}}
              >
                {t('userStepBack')}
              </Button>
              {activeStep === steps.length - 1 ? (
                isFinish ? (
                  <Button disabled>{t('userStepFinish')}</Button>
                ) : (
                  <Button onClick={handleReset}>{t('userStepReset')}</Button>
                )
              ) : (
                <Button
                  onClick={handleNext}
                  disabled={activeStep === 0 && !platformName}
                >
                  {t('userStepNext')}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={ALERT_HIDE_DURATION}
          onClose={handleCloseSnackbar}
        >
          <Alert
            severity={showSnackbarType}
            variant='filled'
            sx={{width: '100%'}}
          >
            {showSnackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
};

export default UserSteps;
