import axios from 'axios';
import {ZUSER_PLATFORM_ID} from '../constant';

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_API}/api/v1/`,
  headers: {'Content-Type': 'application/json; charset=utf-8'},
  timeout: 20000,
  validateStatus: function (status) {
    return status >= 200 && status < 500; // default
  },
});

axiosClient.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token');
    if (token && !config.headers.hasOwnProperty('Authorization')) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    let platformId = localStorage.getItem('platformId');
    if (!platformId) {
      platformId = ZUSER_PLATFORM_ID;
    }
    config.headers['platformid'] = platformId;
    if (config.headers.platformId) {
      config.headers['platformid'] = config.headers.platformId;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log('error', error);
    handleErrorStatus(error.response?.status);
    return error;
  },
);

function handleErrorStatus(status: number) {
  // handle error statuses here if needed
}

function createConfig(isFormData: boolean) {
  if (isFormData) {
    return {headers: {'Content-Type': 'multipart/form-data'}};
  }
  return {headers: {'Content-Type': 'application/json; charset=utf-8'}};
}

export default function (
  method: string,
  url: string,
  data = {},
  config = {},
  isFormData = false,
) {
  method = method.toLowerCase();
  const mergedConfig = {...createConfig(isFormData), ...config};

  switch (method) {
    case 'post':
      return axiosClient[method](url, data, mergedConfig);
    case 'get':
      return axiosClient[method](url, {params: data, ...mergedConfig});
    case 'delete':
      return axiosClient[method](url, {data, ...mergedConfig});
    case 'put':
      return axiosClient[method](url, data, mergedConfig);
    case 'patch':
      return axiosClient[method](url, data, mergedConfig);
  }
}
