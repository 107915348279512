import React, {useState} from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  PaletteMode,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import SecurityIcon from '@mui/icons-material/Security';
import PaymentIcon from '@mui/icons-material/Payment';
import GroupIcon from '@mui/icons-material/Group';
import AppAppBar from '../components/AppAppBar';
import Footer from '../components/Footer';
import {useTranslation} from 'react-i18next';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@mui/lab';

const AboutUsPage = () => {
  const {t, i18n} = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<{
    date?: any;
    event?: string;
    details?: string;
  }>({});

  const handleOpen = (event: any) => {
    setSelectedEvent(event);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const features = [
    {
      icon: <GroupIcon fontSize='large' color='primary' />,
      title: t('awardsFeature1Title'),
      description: t('awardsFeature1Content'),
    },
    {
      icon: <SecurityIcon fontSize='large' color='secondary' />, // 權限設計
      title: t('awardsFeature2Title'),
      description: t('awardsFeature2Content'),
    },
    {
      icon: <PaymentIcon fontSize='large' color='success' />, // 付費計畫服務
      title: t('awardsFeature3Title'),
      description: t('awardsFeature3Content'),
    },
  ];

  const milestones = [
    {
      date: t('awardsTimeline1Date'),
      event: t('awardsTimeline1Title'),
      details: t('awardsTimeline1Content'),
    },
    {
      date: t('awardsTimeline2Date'),
      event: t('awardsTimeline2Title'),
      details: t('awardsTimeline2Content'),
    },
  ];

  return (
    <>
      <AppAppBar />
      <Container maxWidth='lg' style={{padding: '10rem 0'}}>
        <Typography variant='h4' align='center' gutterBottom>
          {t('awardsTitle')}
        </Typography>
        <Typography variant='h6' align='center' color='textSecondary' paragraph>
          {t('awardsContent')}
        </Typography>

        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card
                elevation={3}
                style={{textAlign: 'center', padding: '1.5rem'}}
              >
                <Avatar
                  style={{
                    margin: '0 auto',
                    backgroundColor: '#f5f5f5',
                    width: 60,
                    height: 60,
                  }}
                >
                  {feature.icon}
                </Avatar>
                <CardContent>
                  <Typography variant='h6' gutterBottom>
                    {feature.title}
                  </Typography>
                  <Typography color='textSecondary'>
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Grid container justifyContent='center' style={{marginTop: '3rem'}}>
          <VerifiedIcon fontSize='large' color='action' />
          <Typography
            variant='body1'
            align='center'
            style={{marginLeft: '0.5rem'}}
          >
            {t('awardsSymbol')}
          </Typography>
        </Grid>
        <Typography variant='h5' align='center' style={{marginTop: '5rem'}}>
          歷史里程碑
        </Typography>
        <Timeline style={{padding: '0 0 0 2rem'}}>
          {milestones.map((milestone, index) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot color='primary' />
                {index < milestones.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent
                onClick={() => handleOpen(milestone)}
                style={{cursor: 'pointer'}}
              >
                <Typography variant='h6'>{milestone.date}</Typography>
                <Typography color='textSecondary'>{milestone.event}</Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{selectedEvent?.event}</DialogTitle>
          <DialogContent>
            <Typography>{selectedEvent?.details}</Typography>
          </DialogContent>
        </Dialog>
      </Container>
      <Footer />
    </>
  );
};

export default AboutUsPage;
