import 'swagger-ui-react/swagger-ui.css';
import {RedocStandalone} from 'redoc';
import SEO from '../components/SEO';

export default function APIDocumentationPage() {
  return (
    <>
      <SEO
        title='API 文檔 - ZUsers Platform'
        description='ZUsers Platform 提供詳細的 API 文檔，以幫助開發人員快速入門。'
        image='%PUBLIC_URL%/logo192.png'
        url='https://zusers.zzztech.com.tw/api-document'
      />
      <RedocStandalone
        specUrl={`${process.env.REACT_APP_SERVER_API}/swagger/doc.json`}
        options={{
          nativeScrollbars: true,
          theme: {colors: {primary: {main: '#dd5522'}}},
        }}
      />
    </>
  );
}
