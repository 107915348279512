import React, {useState, useEffect, useRef} from 'react';
import {BaseEdge, getSmoothStepPath, type EdgeProps} from '@xyflow/react';

export function AnimatedSVGEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
}: EdgeProps) {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const duration = 2.5;
  const edgeIndex = parseInt(id, 36);
  const timeoutSec = (edgeIndex - 1) * duration;
  const beginTime = `${timeoutSec}s`;
  const animateRef = useRef<SVGAnimateMotionElement | null>(null);

  useEffect(() => {
    const totalDuration = 6 * duration;

    const interval = setInterval(() => {
      if (animateRef.current) {
        setTimeout(() => {
          animateRef?.current?.beginElement();
        }, timeoutSec * 1000);
      }
    }, totalDuration * 1000);

    return () => clearInterval(interval); // 清除 interval 以防止內存洩漏
  }, []);

  return (
    <>
      <BaseEdge id={id} path={edgePath} />

      <g>
        <circle
          r='6'
          fill='rgba(255, 0, 115, 0.8)'
          stroke='white'
          strokeWidth='2'
        >
          <animateMotion
            ref={animateRef}
            dur={`${duration}s`}
            begin={beginTime}
            repeatCount='1'
            path={edgePath}
          />
        </circle>
      </g>
    </>
  );
}
