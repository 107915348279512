import React, {useState, useEffect} from 'react';
import {
  IconButton,
  Badge,
  Menu,
  MenuItem,
  ListItemText,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {TFunction} from 'i18next';
import {
  GetUserNotificationListDto,
  UserNotificationDto,
} from '../../api/dto/userNotification';
import {
  getUserMeNotificationList,
  patchUserNotification,
} from '../../api/userNotification';
import CloseIcon from '@mui/icons-material/Close';

const NotificationButton = ({t}: {t: TFunction<'translation', undefined>}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = useState<
    Array<UserNotificationDto>
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedNotification, setSelectedNotification] =
    useState<UserNotificationDto | null>(null);

  const unreadCount = notifications.filter(n => !n.isRead).length;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = async (notification: UserNotificationDto) => {
    setSelectedNotification(notification);

    if (!notification.isRead) {
      try {
        await patchUserNotification(notification.id, {isRead: true});
        setNotifications(prev =>
          prev.map(n => (n.id === notification.id ? {...n, isRead: true} : n)),
        );
      } catch (error) {
        console.error('Failed to mark notification as read:', error);
      }
    }
  };

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const data: GetUserNotificationListDto = {
        orderBy: 'createdAt',
        sort: 'DESC',
      };
      const response = await getUserMeNotificationList(data);
      setNotifications(response?.data?.items ?? []);
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <>
      <IconButton color='inherit' onClick={handleClick}>
        <Badge badgeContent={unreadCount} color='secondary'>
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '12px',
            backgroundColor: '#001F3F',
            boxShadow: '0 4px 20px rgba(0, 160, 255, 0.2)',
            backdropFilter: 'blur(10px)',
            border: '1px solid #007ACC',
            color: 'white',
          },
        }}
      >
        {loading ? (
          <MenuItem
            disabled
            sx={{display: 'flex', justifyContent: 'center', width: '320px'}}
          >
            <CircularProgress size={24} sx={{color: '#00AEEF'}} />
          </MenuItem>
        ) : notifications.length > 0 ? (
          <>
            <MenuItem
              disabled
              sx={{
                width: '320px',
                textAlign: 'center',
                fontWeight: 'bold',
                fontFamily: `'Orbitron', sans-serif`,
                color: '#00FFFF',
                opacity: 0.9,
                borderBottom: '1px solid rgba(0, 190, 255, 0.3)',
              }}
            >
              {t('notificationPageTitle')} ({notifications.length})
            </MenuItem>
            {notifications.map((notification, index) => (
              <React.Fragment key={notification.id}>
                <MenuItem
                  onClick={() => handleNotificationClick(notification)}
                  sx={{
                    width: '320px',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    transition: 'all 0.3s ease',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 160, 255, 0.1)',
                      boxShadow: '0 0 10px rgba(0, 160, 255, 0.4)',
                    },
                  }}
                >
                  <ListItemText
                    primary={notification.title}
                    primaryTypographyProps={{
                      sx: {
                        fontWeight: notification.isRead ? 'normal' : 'bold',
                        color: notification.isRead
                          ? 'rgba(200, 200, 200, 0.8)'
                          : '#00AEEF',
                        fontFamily: `'Orbitron', sans-serif`,
                      },
                    }}
                  />
                </MenuItem>
                {index < notifications.length - 1 && (
                  <Divider sx={{backgroundColor: 'rgba(0, 160, 255, 0.3)'}} />
                )}
              </React.Fragment>
            ))}
          </>
        ) : (
          <MenuItem
            disabled
            sx={{width: '320px', textAlign: 'center', opacity: 0.7}}
          >
            <Typography color='rgba(200, 200, 200, 0.7)'>
              {t('notificationPageNoNotify')}
            </Typography>
          </MenuItem>
        )}
      </Menu>

      {/* 彈窗顯示通知內容 */}
      <Dialog
        open={Boolean(selectedNotification)}
        onClose={() => setSelectedNotification(null)}
        PaperProps={{
          sx: {
            borderRadius: '20px',
            boxShadow: '0 10px 40px rgba(0, 160, 255, 0.2)',
            padding: '24px',
            maxWidth: '80rem',
            minWidth: '40rem',
            backdropFilter: 'blur(10px)',
            backgroundColor: '#001F3F', // 深藍色背景
            border: '1px solid #007ACC', // 螢光藍邊框
            color: 'white',
          },
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontWeight: 'bold',
            fontSize: '1.5rem',
            borderBottom: '1px solid #007ACC',
            paddingBottom: '12px',
            textTransform: 'uppercase',
            letterSpacing: '1px',
            color: '#00AEEF', // 亮藍色文字
          }}
        >
          {selectedNotification?.title}
          <IconButton
            onClick={() => setSelectedNotification(null)}
            sx={{
              color: '#00AEEF',
              '&:hover': {color: '#007ACC', transition: '0.3s ease'},
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{padding: '20px 32px'}}>
          <Typography
            sx={{
              fontSize: '1.1rem',
              color: '#B0C4DE', // 淺藍文字提升可讀性
              lineHeight: '1.8',
              wordBreak: 'break-word',
              fontFamily: `'Orbitron', sans-serif`,
            }}
          >
            {selectedNotification?.message}
          </Typography>
        </DialogContent>

        <DialogActions sx={{padding: '12px 32px', justifyContent: 'flex-end'}}>
          <Button
            onClick={() => setSelectedNotification(null)}
            variant='contained'
            sx={{
              borderRadius: '8px',
              textTransform: 'uppercase',
              padding: '10px 24px',
              fontWeight: 'bold',
              backgroundColor: '#007ACC', // 純藍色按鈕
              color: 'white',
              '&:hover': {
                backgroundColor: '#00AEEF',
                boxShadow: '0 0 10px #00AEEF',
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationButton;
