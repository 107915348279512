import React, {useState, useEffect} from 'react';
import {Box, Button, Typography} from '@mui/material';
import {useParams, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {getPlanPublic} from '../../../api/plan';
import {PlanDto} from '../../../api/dto/plan';
import {DEFAULT_LANG} from '../../../constant';
import TapPayPayment from '../../../components/Tappay/Form';
import {getUserMe} from '../../../api/user';
import {UserDto} from '../../../api/dto/user';
import AppAppBar from '../../../components/AppAppBar';

declare global {
  interface Window {
    TPDirect: any;
  }
}

const SubscriptionPage: React.FC = () => {
  const localeI18n = localStorage.getItem('i18n') || DEFAULT_LANG;
  const {t, i18n} = useTranslation();
  const {planId} = useParams();
  const navigate = useNavigate();
  const [plan, setPlan] = useState<PlanDto>();
  const [user, setUser] = useState<UserDto | null>(null);

  useEffect(() => {
    i18n.changeLanguage(localeI18n as string);
  }, []);

  useEffect(() => {
    const fetchPlan = async () => {
      try {
        if (planId) {
          const response = await getPlanPublic(planId);
          if (response?.data?.data) {
            setPlan(response.data.data);
          }
        }
      } catch (error) {
        console.error('Failed to fetch plan info:', error);
      }
    };

    const fetchUser = async () => {
      try {
        const response = await getUserMe();
        if (response?.data?.data) {
          setUser(response.data.data);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Failed to fetch user info:', error);
        setUser(null);
      }
    };

    fetchUser();
    fetchPlan();
  }, [planId]);

  if (!user) {
    return (
      <Box
        sx={{
          marginTop: '6rem',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography variant='h6' sx={{mb: 2}}>
          {t('planPayNeedLoginContent')}
        </Typography>
        <Button
          variant='contained'
          color='primary'
          onClick={() => navigate('/sign-in')}
        >
          {t('planPayNeedLoginGoLogin')}
        </Button>
      </Box>
    );
  }

  if (!planId) {
    return (
      <Box
        sx={{
          marginTop: '6rem',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      ></Box>
    );
  }

  return (
    <>
      <AppAppBar></AppAppBar>
      <Box
        sx={{
          marginTop: '6rem',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            mb: 3,
            textAlign: 'center',
          }}
        >
          <Typography variant='h6' gutterBottom>
            {t('planPayNoticeTitle')}
          </Typography>
          <Box
            sx={{
              textAlign: 'left',
              display: 'inline-block',
            }}
          >
            <Typography variant='body2' color='textSecondary'>
              {t('planPayNoticeRule1')}
            </Typography>
            <Typography variant='body2' color='textSecondary'>
              {t('planPayNoticeRule2')}
            </Typography>
            <Typography variant='body2' color='textSecondary'>
              {t('planPayNoticeRule3')}
            </Typography>
          </Box>
        </Box>
        <TapPayPayment user={user} i18n={localeI18n} planId={planId} t={t} />
      </Box>
    </>
  );
};

export default SubscriptionPage;
