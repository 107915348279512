import React, {useEffect, useState} from 'react';
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  Node,
  Edge,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import {AnimatedSVGEdge} from './AnimateSvg';
import {useTranslation} from 'react-i18next';
import {DEFAULT_LANG} from '../../constant';
import CustomNode from './CustomNode';
import {
  PersonAdd,
  Build,
  Person,
  Security,
  Assignment,
  NotificationImportant,
  CheckCircle,
} from '@mui/icons-material';

const nodeTypes = {
  custom: CustomNode,
};

const parseMermaidToReactFlow = (t: (key: string) => string) => {
  const nodes: Node[] = [
    {
      id: 'A',
      position: {x: -50, y: -30},
      type: 'custom',
      data: {
        label: t('flowChartMermaidRegister'),
        icon: <PersonAdd />,
        link: '/sign-up',
      },
    },
    {
      id: 'B',
      position: {x: 200, y: 0},
      type: 'custom',
      data: {
        label: t('flowChartMermaidCreatePlatform'),
        icon: <Build />,
        link: '/user/step',
      },
    },
    {
      id: 'group1',
      data: {label: 'Group A'},
      position: {x: 370, y: -200},
      style: {
        width: 215,
        height: 450,
        backgroundColor: 'rgba(255, 0, 255, 0.1)',
      },
      type: 'group',
    },
    {
      id: 'C',
      position: {x: 400, y: 0},
      type: 'custom',
      data: {
        label: t('flowChartMermaidConnectUser'),
        icon: <Person />,
        link: '/api-document#tag/user',
      },
    },
    {
      id: 'C1',
      position: {x: 400, y: -140},
      type: 'custom',
      data: {
        label: t('flowChartMermaidConnectPermission'),
        icon: <Security />,
        link: '/api-document#tag/platformPermission',
      },
      className: 'optionalNode',
    },
    {
      id: 'C2',
      position: {x: 400, y: 120},
      type: 'custom',
      data: {
        label: t('flowChartMermaidConnectPlan'),
        icon: <Assignment />,
        link: '/api-document#tag/plan',
      },
      className: 'optionalNode',
    },
    {
      id: 'D1',
      position: {x: 600, y: -120},
      type: 'custom',
      data: {
        label: t('flowChartMermaidReceiveWebhook'),
        icon: <NotificationImportant />,
        link: '/dashboard/platforms',
      },
    },
    {
      id: 'F',
      position: {x: 1000, y: 0},
      type: 'custom',
      data: {label: t('flowChartMermaidFinish'), icon: <CheckCircle />},
    },
  ];

  const edges: Edge[] = [
    {id: '1', type: 'animatedSvg', source: 'A', target: 'B'},
    {id: '2', type: 'animatedSvg', source: 'B', target: 'C'},
    {
      id: '3',
      type: 'animatedSvg',
      source: 'B',
      target: 'C1',
      animated: true,
    },
    {
      id: '4',
      type: 'animatedSvg',
      source: 'B',
      target: 'C2',
      animated: true,
    },
    {
      id: '5',
      type: 'animatedSvg',
      source: 'C',
      target: 'D1',
      animated: true,
    },
    {id: '6', type: 'animatedSvg', source: 'C', target: 'F'},
  ];

  return {nodes, edges};
};

const FlowChart = () => {
  const localeI18n = localStorage.getItem('i18n') || DEFAULT_LANG;
  const {t, i18n} = useTranslation();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {
    i18n.changeLanguage(localeI18n as string);
  }, [localeI18n, i18n]);

  useEffect(() => {
    const {nodes: updatedNodes, edges: updatedEdges} =
      parseMermaidToReactFlow(t);
    setNodes(updatedNodes as any);
    setEdges(updatedEdges as any);
  }, [t, setNodes, setEdges]);

  const proOptions = {hideAttribution: true};
  return (
    <div
      style={{
        width: '100%',
        maxWidth: '70%',
        height: '400px',
        backgroundColor: '#EAF0F6',
        border: '1px solid #ccc',
        borderRadius: '8px',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'auto',
      }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        edgeTypes={{animatedSvg: AnimatedSVGEdge}}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        fitView
        fitViewOptions={{padding: 0.1}}
        style={{backgroundColor: '#F7F9FB'}}
        zoomOnScroll={false}
        panOnScroll={false}
        zoomOnPinch={false}
        zoomOnDoubleClick={false}
        proOptions={proOptions}
        nodesDraggable
        nodeTypes={nodeTypes}
      >
        {/* <Background /> */}
      </ReactFlow>
    </div>
  );
};

export default FlowChart;
