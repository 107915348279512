import {Typography, Button, Container, Grid} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

const ErrorPage = () => {
  const {t, i18n} = useTranslation();
  const localeI18n = localStorage.getItem('i18n');

  useEffect(() => {
    i18n.changeLanguage(localeI18n as string);
  }, []);

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <Container maxWidth='sm' sx={{mt: 10, textAlign: 'center'}}>
      <Grid
        container
        spacing={3}
        justifyContent='center'
        alignItems='center'
        direction='column'
      >
        <Grid item>
          <ErrorOutlineIcon color='error' sx={{fontSize: 100}} />
        </Grid>
        <Grid item>
          <Typography variant='h4' color='error' gutterBottom>
            {t('errorTitle')}
          </Typography>
          <Typography variant='body1' color='textSecondary'>
            {t('errorContent')}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant='contained'
            color='primary'
            onClick={handleGoBack}
            sx={{mt: 2}}
          >
            {t('errorGoBack')}
          </Button>
          <Button
            variant='outlined'
            color='secondary'
            href='/'
            sx={{mt: 2, ml: 2}}
          >
            {t('errorHomePage')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ErrorPage;
