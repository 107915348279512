import * as React from 'react';
import {useSprings, animated} from 'react-spring';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import {useTranslation} from 'react-i18next';
import {getPlanPublicList} from '../api/plan';
import {PlanDto} from '../api/dto/plan';
import {ArrowBack, ArrowForward} from '@mui/icons-material';
import {TFunction} from 'i18next';

export function PricingCard({
  plan,
  t,
}: {
  plan: PlanDto;
  t: TFunction<'translation', undefined>;
}) {
  const localeI18n = localStorage.getItem('i18n');
  return (
    <animated.div key={plan?.id}>
      <Card
        sx={{
          minWidth: '360px',
          maxWidth: '360px',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          border: plan.name === 'Pro' ? '1px solid' : undefined,
          borderColor: plan.name === 'Pro' ? 'primary.main' : undefined,
          background:
            plan.name === 'Pro'
              ? 'linear-gradient(#033363, #021F3B)'
              : undefined,
        }}
      >
        <CardContent>
          <Box
            sx={{
              mb: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: plan.name === 'Pro' ? 'grey.100' : '',
            }}
          >
            <Typography component='h3' variant='h6'>
              {t(`pricing${plan.name}`)}
            </Typography>
            {plan.name === 'Pro' && (
              <Chip
                icon={<AutoAwesomeIcon />}
                label={t('pricingRecommended')}
                size='small'
                sx={{
                  background: theme =>
                    theme.palette.mode === 'light' ? '' : 'none',
                  backgroundColor: 'primary.contrastText',
                  '& .MuiChip-label': {
                    color: 'primary.dark',
                  },
                  '& .MuiChip-icon': {
                    color: 'primary.dark',
                  },
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'baseline',
              gap: 0.5,
              color: plan.name === 'Pro' ? 'grey.50' : undefined,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 0.5,
              }}
            >
              {plan.i18nPriceObj?.[localeI18n || '']?.specialPrice && (
                <Typography
                  component='span'
                  variant='subtitle2'
                  sx={{
                    textDecoration: 'line-through',
                    color: 'text.secondary',
                    fontWeight: 400,
                  }}
                >
                  ${plan.i18nPriceObj?.[localeI18n || '']?.price || '0'}
                </Typography>
              )}
              <Typography component='h3' variant='h2'>
                $
                {plan.i18nPriceObj?.[localeI18n || '']?.specialPrice ||
                  plan.i18nPriceObj?.[localeI18n || '']?.price ||
                  '0'}
              </Typography>
            </Box>
            <Typography
              component='h6'
              variant='subtitle2'
              sx={{
                color: plan.name === 'Pro' ? 'grey.300' : 'text.secondary',
                fontWeight: 400,
              }}
            >
              {plan.i18nPriceObj?.[localeI18n || '']?.currency}
            </Typography>
            <Typography component='h6' variant='h6'>
              {t('pricingPerMonth')}
            </Typography>
          </Box>
          <Divider
            sx={{
              my: 2,
              opacity: 0.2,
              borderColor: 'grey.500',
            }}
          />
          {Object.keys(plan.ruleObj || {}).map(rule => (
            <Box
              key={rule}
              sx={{
                py: 1,
                display: 'flex',
                gap: 1.5,
                alignItems: 'center',
              }}
            >
              <CheckCircleRoundedIcon
                sx={{
                  width: 20,
                  color: plan.name === 'Pro' ? 'primary.light' : 'primary.main',
                }}
              />
              <Typography
                component='text'
                variant='subtitle2'
                sx={{
                  color: plan.name === 'Pro' ? 'grey.200' : undefined,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'block',
                  maxWidth: '350px',
                }}
              >
                {typeof plan.ruleObj[rule] === 'boolean'
                  ? `${t(`pricingRule_${rule}`)}`
                  : `${t(`pricingRule_${rule}`)}: ${plan.ruleObj[rule]}`}
              </Typography>
            </Box>
          ))}
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            variant='contained'
            component='a'
            href={plan.name === 'Free' ? '/sign-up' : `/order/pay/${plan.id}`}
          >
            {plan.name === 'Free' ? t('signupForFree') : t('buyPlan')}
          </Button>
        </CardActions>
      </Card>
    </animated.div>
  );
}

export default function Pricing() {
  const {t} = useTranslation();
  const [plans, setPlans] = React.useState<PlanDto[]>([]);
  const localeI18n = localStorage.getItem('i18n');

  React.useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await getPlanPublicList();
        setPlans(response?.data.items || []);
      } catch (error) {
        console.error('Failed to fetch plans:', error);
      }
    };
    fetchPlans();
  }, []);

  // 使用 useSprings 設置動畫
  const springs = useSprings(
    plans.length,
    plans.map((_, index) => ({
      from: {opacity: 0, transform: 'translate3d(0,50px,0)'},
      to: {opacity: 1, transform: 'translate3d(0,0,0)'},
      delay: index * 550,
    })),
  );

  const [currentIndex, setCurrentIndex] = React.useState(0); // 當前可見卡片的起始索引
  const visibleCount = 3;

  const handleNext = () => {
    console.log('\x1b[32m', '\n---- Debug ----\n');

    if (currentIndex + visibleCount < plans.length) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <Container
      id='pricing'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
        }}
      >
        <Typography component='h2' variant='h4' color='text.primary'>
          {t('pricing')}
        </Typography>
        <Typography variant='body1' color='text.secondary'>
          {t('pricingDescription')}
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems='center' justifyContent='center'>
        <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
          {/* 左箭頭 */}
          <Button
            onClick={handlePrev}
            disabled={currentIndex === 0}
            sx={{minWidth: 0, padding: 1}}
          >
            <ArrowBack />
          </Button>

          <Box
            sx={{
              display: 'flex',
              gap: 2,
              overflow: 'hidden',
              width: '100%', // 確保容器寬度固定
            }}
          >
            {plans
              .slice(currentIndex, currentIndex + visibleCount)
              .map((plan, index) => (
                <PricingCard plan={plan} t={t} />
              ))}
          </Box>
          <Button
            onClick={handleNext}
            disabled={currentIndex + visibleCount >= plans.length}
            sx={{minWidth: 0, padding: 1}}
          >
            <ArrowForward />
          </Button>
        </Box>
      </Grid>
    </Container>
  );
}
