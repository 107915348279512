import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {PlatformDto} from '../../api/dto/platform';

export default function AuthIcon({platform}: {platform: PlatformDto}) {
  const imageElement = (
    <img
      src={platform?.icon}
      alt={`${platform?.name} Icon`}
      style={{
        width: 100,
        height: 100,
        objectFit: 'contain',
        transition: 'transform 0.2s ease-in-out',
      }}
      onMouseEnter={e => (e.currentTarget.style.transform = 'scale(1.1)')}
      onMouseLeave={e => (e.currentTarget.style.transform = 'scale(1)')}
      onClick={e => {
        const imgElement = e.target as HTMLImageElement;
        imgElement.style.transform = 'scale(1.2)';
        setTimeout(() => {
          if (imgElement) imgElement.style.transform = 'scale(1)';
        }, 200);
      }}
    />
  );

  return (
    <>
      {platform?.icon ? (
        platform?.homePageUrl ? (
          <a
            href={platform.homePageUrl}
            target='_blank'
            rel='noopener noreferrer'
          >
            {imageElement}
          </a>
        ) : (
          imageElement
        )
      ) : (
        <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
          <LockOutlinedIcon />
        </Avatar>
      )}
    </>
  );
}
