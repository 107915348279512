import {
  Grid,
  TextField,
  Button,
  Link,
  Typography,
  Box,
  ThemeProvider,
  createTheme,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import {FormEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './ForgetPassword.css';
import {postUserForgetPassword} from '../api/user';
import {HttpStatusCode} from 'axios';
import {getPlatformPublicList} from '../api/platform';
import {PlatformDto} from '../api/dto/platform';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5', // Customize primary color
    },
    background: {
      default: '#f4f6f8', // Light background color
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

const ForgotPasswordPage = () => {
  const {t, i18n} = useTranslation();
  const localeI18n = localStorage.getItem('i18n');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [platformList, setPlatformList] = useState<PlatformDto[]>();
  const [platformId, setPlatformId] = useState<string>();
  const [searchPlatformId, setSearchPlatformId] = useState<string>('');
  const [disabledPlatformId, setDisabledPlatformId] = useState<boolean>(false);
  const [cancelUrl, setCancelUrl] = useState<string | undefined>();
  const [returnUrl, setReturnUrl] = useState<string | undefined>();

  const [cooldown, setCooldown] = useState(0);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (cooldown > 0) {
      timer = setTimeout(() => {
        setCooldown(cooldown - 1);
      }, 1000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [cooldown]);

  useEffect(() => {
    i18n.changeLanguage(localeI18n as string);
  }, []);

  useEffect(() => {
    const fetchPlatformList = async () => {
      setLoading(true);

      getPlatformPublicList({limit: 1000})
        ?.then(d => {
          const platformList = d?.data ? d?.data.items.map(item => item) : [];
          setPlatformList(platformList);
        })
        .catch(e => {
          setError(e.message || 'Failed to load platforms');
        });
    };

    fetchPlatformList();
  }, []);

  useEffect(() => {
    if (!platformList || platformList.length === 0) return;

    const urlSearch = new URLSearchParams(window.location.search);
    const platformId = urlSearch.get('platformId');
    const returnUrlParam = urlSearch.get('returnUrl');
    const cancelUrlParam = urlSearch.get('cancelUrl');

    if (platformId && platformList) {
      platformList.forEach(platform => {
        if (platform.id === platformId) {
          const {returnUrl = [], cancelUrl = []} = platform;

          if (returnUrlParam && !returnUrl.includes(returnUrlParam)) {
            setError(t('signinReturnUrlError'));
            return;
          }
          if (cancelUrlParam && !cancelUrl.includes(cancelUrlParam)) {
            setError(t('signinCancelUrlError'));
            return;
          }

          setPlatformId(platform.id);
          localStorage.setItem('platformId', platformId);
          setReturnUrl(returnUrlParam ?? undefined);
          setCancelUrl(cancelUrlParam ?? undefined);
        }
      });

      setSearchPlatformId(platformId);
      setDisabledPlatformId(true);
    }
    setLoading(false);
  }, [platformList, t]);

  const handleSubmit = async (): Promise<boolean> => {
    setSubmitLoading(true);

    if (!platformId || !email) {
      setError(t('forgetPasswordEmailAndPlatformIsRequired'));
      return false;
    }

    setError('');
    setSuccess('');
    try {
      const result = await postUserForgetPassword({
        email,
        returnUrl,
        cancelUrl,
        platformId,
      });
      if (result && result.status === HttpStatusCode.Ok) {
        setSuccess(t('forgetPasswordSuccess'));
        return true;
      } else {
        setError(JSON.stringify(result?.data));
        return false;
      }
    } catch (err: any) {
      setError(err.response?.data?.message || t('forgetPasswordError'));
      return false;
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleClick = async () => {
    if (!submitLoading && cooldown === 0) {
      const isOk = await handleSubmit(); // 調用提交邏輯
      if (isOk) {
        setCooldown(60); // 設置 60 秒冷卻時間
      }
    }
  };

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <div className='background-animation'>
          <div className='circle'></div>
          <div className='circle'></div>
          <div className='circle'></div>
          <div className='circle'></div>
          <div className='circle'></div>
        </div>
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          style={{
            minHeight: '100vh',
            backgroundColor: 'rgba(249, 249, 249, 0.5)',
          }}
        ></Grid>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div className='background-animation'>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
      </div>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        style={{
          minHeight: '100vh',
          backgroundColor: 'rgba(249, 249, 249, 0.5)',
        }}
      >
        <Grid
          item
          xs={11}
          sm={8}
          md={5}
          lg={4}
          style={{
            backgroundColor: 'white',
            padding: '2rem',
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography variant='h5' align='center' gutterBottom>
            {t('forgetPasswordTitle')}
          </Typography>
          <Typography
            variant='body2'
            align='center'
            color='textSecondary'
            gutterBottom
          >
            {t('forgetPasswordContent')}
          </Typography>

          <FormControl required fullWidth disabled={disabledPlatformId}>
            <InputLabel id='platform-simple-select-label'>
              {t('signinPlatformName')}
            </InputLabel>
            <Select
              labelId='platform-simple-select-label'
              id='platform-simple-select'
              value={searchPlatformId || platformId}
              label={t('signinPlatformName')}
              onChange={v => {
                localStorage.setItem('platformId', v.target.value);
                setPlatformId(v.target.value);
              }}
            >
              {platformList &&
                platformList
                  .filter(p => (disabledPlatformId ? p : !!p.isActive))
                  .map(g => (
                    <MenuItem key={g.id} value={g.id}>
                      {g.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>

          <Box component='form' noValidate sx={{mt: 3}} onSubmit={handleSubmit}>
            <TextField
              fullWidth
              required
              id='email'
              label='Email Address'
              variant='outlined'
              margin='normal'
              value={email}
              onChange={e => setEmail(e.target.value)}
              error={Boolean(error)}
              helperText={error || ''}
            />

            <Button
              fullWidth
              variant='contained'
              color='primary'
              sx={{mt: 2, mb: 2, py: 1.5, textTransform: 'none'}}
              disabled={submitLoading || cooldown > 0}
              onClick={handleClick}
            >
              {submitLoading ? (
                <CircularProgress size={24} color='inherit' />
              ) : cooldown > 0 ? (
                `Wait ${cooldown}s`
              ) : (
                'Submit'
              )}
            </Button>

            {success && (
              <Typography variant='body2' color='success.main' align='center'>
                {success}
              </Typography>
            )}

            <Grid container justifyContent='space-between' alignItems='center'>
              <Link href='/sign-in' variant='body2'>
                {t('forgetPasswordBackToSignin')}
              </Link>
              <Link href='/sign-up' variant='body2'>
                {t('forgetPasswordBackToSignup')}
              </Link>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default ForgotPasswordPage;
