import React, {useState, useEffect} from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Alert,
  ThemeProvider,
  createTheme,
  Container,
  InputAdornment,
  IconButton,
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useQuery} from '../../hooks/useQuery';
import {useTranslation} from 'react-i18next';
import {
  patchUserResetPassword,
  postUserResetPasswordCheck,
} from '../../api/user';
import {HttpStatusCode} from 'axios';
import '../SignIn.css';
import {Visibility, VisibilityOff} from '@mui/icons-material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    background: {
      default: '#f4f6f8',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

const ResetPasswordPage: React.FC = () => {
  const {t, i18n} = useTranslation();
  const localeI18n = localStorage.getItem('i18n');
  const query = useQuery();
  const navigate = useNavigate();
  const [tokenValid, setTokenValid] = useState<boolean | null>(null);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handlePasswordVisibilityToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordVisibilityToggle = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const token = query.get('token');
  const validateToken = async (token: string): Promise<boolean> => {
    const result = await postUserResetPasswordCheck({token});
    if (result && result.status === HttpStatusCode.Ok) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!token) {
      navigate('/error');
    } else {
      validateToken(token).then(isValid => {
        setTokenValid(isValid);
        if (!isValid) {
          navigate('/error');
        }
      });
    }
  }, [token, navigate]);

  useEffect(() => {
    i18n.changeLanguage(localeI18n as string);
  }, []);

  const handleSubmit = async () => {
    setError(null);
    if (password !== confirmPassword) {
      setError(t('userResetPasswordNotMatch'));
      return;
    }
    setLoading(true);
    try {
      const result = await patchUserResetPassword({
        token: token ?? '',
        password: password,
      });
      if (result && result.status === HttpStatusCode.Ok) {
        setSuccess(true);
        setTimeout(() => navigate(`/sign-in${window.location.search}`), 3500);
      } else {
        setError(JSON.stringify(result?.data));
      }
    } catch (e) {
      setError(t('userResetPasswordErrorMsg'));
    } finally {
      setLoading(false);
    }
  };

  if (tokenValid === null) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        minHeight='100vh'
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div className='background-animation'>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
      </div>
      <Container component='main' maxWidth='xs'>
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: 2,
            boxShadow: 3,
            p: 4,
            mt: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant='h5' gutterBottom>
            {t('userResetPassword')}
          </Typography>
          {error && <Alert severity='error'>{error}</Alert>}
          {success && (
            <Alert severity='success'>{t('userResetPasswordSuccessMsg')}</Alert>
          )}
          {!success && (
            <>
              <TextField
                label={t('userResetPasswordLabel')}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                margin='normal'
                value={password}
                onChange={e => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={handlePasswordVisibilityToggle}
                        edge='end'
                        aria-label={
                          showPassword ? 'Hide password' : 'Show password'
                        }
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                label={t('userResetPasswordConfirmLabel')}
                type={showConfirmPassword ? 'text' : 'password'}
                fullWidth
                margin='normal'
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={handleConfirmPasswordVisibilityToggle}
                        edge='end'
                        aria-label={
                          showConfirmPassword
                            ? 'Hide password'
                            : 'Show password'
                        }
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                variant='contained'
                color='primary'
                fullWidth
                sx={{mt: 2}}
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  t('userResetPasswordSubmit')
                )}
              </Button>
            </>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ResetPasswordPage;
