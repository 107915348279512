import {useTranslation} from 'react-i18next';
import Dashboard from '../../components/Dashboard';
import React, {ReactElement, useMemo, useState} from 'react';
import TWithProviders, {
  MRT_ColumnDef_Add_Show,
  validateRequired,
} from '../../components/MaterialTable';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Alert,
  AlertColor,
  Box,
  Container,
  Grid,
  Snackbar,
  SnackbarOrigin,
  Typography,
} from '@mui/material';
import {CreatedAtColumn} from '../../components/Date/CreatedAtColumn';
import {UserInfoCell} from '../../components/User/TabelCell';
import {
  createUserNotification,
  patchUserNotification,
  getUserNotificationList,
  deleteUserNotification,
} from '../../api/userNotification';
import {ALERT_HIDE_DURATION} from '../../constant';
import {StatusColumn} from '../../components/EditTable/ViewTableStatusColumn';

dayjs.extend(utc);

function validate(data: any) {
  return {
    title: !validateRequired(data.title) ? 'Title is Required' : '',
  };
}

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}
function UserNotificationContent(): ReactElement {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const {t} = useTranslation();

  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;

  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  const columns = useMemo<MRT_ColumnDef_Add_Show<any>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
        show: false,
        size: 80,
      },
      {
        accessorKey: 'title',
        header: t('titleHeader'),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.title,
          helperText: validationErrors?.title,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              title: undefined,
            }),
        },
      },
      {
        accessorKey: 'message',
        header: 'Message',
        show: false,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.title,
          helperText: validationErrors?.title,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              title: undefined,
            }),
        },
      },
      {
        accessorKey: 'status',
        header: t('statusHeader'),
        editVariant: 'select',
        editSelectOptions: [
          {labelKey: t('statusFinish'), value: 'Finish'},
          {labelKey: t('statusFail'), value: 'Fail'},
          {labelKey: t('statusPending'), value: 'Pending'},
        ],
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.status,
          helperText: validationErrors?.status,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              status: undefined,
            }),
        },
        Cell: ({cell}) => <StatusColumn t={t} cell={cell} />,
        accessorFn: row => {
          return row.status;
        },
      },
      {
        accessorKey: 'userId',
        header: t('userHeader'),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.userId,
          helperText: validationErrors?.userId,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              userId: undefined,
            }),
        },
        Cell: ({row}) => <UserInfoCell t={t} user={row.original.user} />,
      },
      {
        accessorKey: 'createdAt',
        header: t('createdAtHeader'),
        enableEditing: false,
        Cell: ({cell}) => <CreatedAtColumn cell={cell} />,
      },
    ],
    [t, validationErrors],
  );

  return (
    <Container
      id='pricing'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
        }}
      >
        <Typography component='h2' variant='h4' color='text.primary'>
          {t('dashboardMenuUserNotification')}
        </Typography>
        <Typography variant='body1' color='text.secondary'>
          {t('dashboardTableUserNotificationDescription')}
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        alignItems='center'
        justifyContent='center'
      ></Grid>
      <TWithProviders
        props={{
          createApi: createUserNotification,
          updateApi: patchUserNotification,
          getListApi: getUserNotificationList,
          deleteApi: deleteUserNotification,
        }}
        columns={columns}
        validate={validate}
        setValidateErrors={setValidationErrors}
        handleClick={handleClick}
      ></TWithProviders>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardUserNotification() {
  return <Dashboard element={UserNotificationContent()}></Dashboard>;
}
