import {AxiosResponse} from 'axios';
import {
  GetVerificationListDto,
  PostVerificationDto,
  VerificationDto,
} from './dto/verification';
import callApi from './index';

export const createVerification = (
  data: PostVerificationDto,
): Promise<AxiosResponse<{data: VerificationDto}, any>> | undefined => {
  return callApi('post', `/verification`, data);
};

export const getVerificationList = (
  data: GetVerificationListDto,
): Promise<AxiosResponse<{items: VerificationDto[]}, any>> | undefined => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/verification/list`, options);
};

export const deleteVerification = (id: string) => {
  return callApi('delete', `/verification/${id}`);
};
