import {useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  Divider,
} from '@mui/material';

export const UserInfoCell = ({user, t}: any) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
      <span
        onClick={handleOpen}
        style={{
          cursor: 'pointer',
          color: '#007bff',
          fontWeight: 'bold',
          textDecoration: 'underline',
        }}
      >
        {user.username}
      </span>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
        <DialogTitle sx={{bgcolor: '#f5f5f5', fontWeight: 'bold'}}>
          {t('userVerificationUserTitle')}
        </DialogTitle>
        <DialogContent dividers>
          {user ? (
            <Box
              sx={{
                p: 2,
                typography: 'body1',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography>
                <strong>ID:</strong> {user.id}
              </Typography>
              <Divider />
              <Typography>
                <strong>{t('userVerificationEmail')}:</strong> {user.email}
              </Typography>
              <Divider />
              <Typography>
                <strong>{t('userVerificationUsername')}:</strong>{' '}
                {user.username}
              </Typography>
              <Divider />
              <Typography>
                <strong>{t('userVerificationLoginType')}:</strong>{' '}
                {user.loginType}
              </Typography>
              <Divider />
              <Typography>
                <strong>{t('userVerificationLastLogin')}:</strong>{' '}
                {new Date(user.lastLoginAt).toLocaleString()}
              </Typography>
              <Divider />
              <Typography>
                <strong>{t('userVerificationIsEmailVerify')}:</strong>{' '}
                <span
                  style={{
                    color: user.isEmailVerify ? 'green' : 'red',
                    fontWeight: 'bold',
                  }}
                >
                  {user.isEmailVerify ? t('yes') : t('no')}
                </span>
              </Typography>

              <Typography>
                <strong>{t('userVerificationIsSuspension')}:</strong>{' '}
                <span
                  style={{
                    color: user.isSuspension ? 'red' : 'green',
                    fontWeight: 'bold',
                  }}
                >
                  {user.isSuspension ? t('yes') : t('no')}
                </span>
              </Typography>
            </Box>
          ) : (
            <Typography color='error' align='center'>
              無法獲取用戶資訊
            </Typography>
          )}
        </DialogContent>
        <DialogActions sx={{justifyContent: 'center', bgcolor: '#f5f5f5'}}>
          <Button onClick={handleClose} variant='contained' color='primary'>
            {t('userVerificationClose')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
